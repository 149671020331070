import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import { Link } from "gatsby"

export default () => (
  <Layout>
    <section id="services" className="py-10 lg:pb-20 lg:pt-24">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Sustain Med Demo</h2>
        <div className='flex flex-col sm:flex-row sm:-mx-3 mt-12'>
          <div className='flex-1 px-3'>
            <Link to="/reports/acquisitions" >
              <Card className='mb-8'>
                <div class="px-12 pb-4">
                  <SvgCharts />
                </div>
                <p className='font-semibold text-xl'>Acquisition Reports</p>
              </Card>
            </Link>
          </div>
          <div className='flex-1 px-3'>
            <Link to="/reports/listings" >
              <Card className='mb-8'>
                <div class="px-12 pb-4">
                  <SvgCharts />
                </div>
                <p className='font-semibold text-xl'>Listing Reports</p>
              </Card>
            </Link>
          </div>
          <div className='flex-1 px-3'>
            <Link to="/notifications" >
              <Card className='mb-8'>
                <div class="px-12 pb-4">
                  <SvgCharts />
                </div>
                <p className='font-semibold text-xl'>Notifications</p>
              </Card>
            </Link>
          </div>
          <div className='flex-1 px-3'>
            <Link to="/marketplace" >
              <Card className='mb-8'>
                <div class="px-12 pb-4">
                  <SvgCharts />
                </div>
                <p className='font-semibold text-xl'>Marketplace</p>
              </Card>
            </Link>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
